<template>
    <page-layout title="Become a Tutor" linkDefault="/" :backButton="true" >
      <div class=" h-screen place-items-center mt-36">
      <div class="flex flex-col justify-center items-center space-y-8">
        <svg width="134" height="115" viewBox="0 0 134 115" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M85.96 104.511C107.929 104.511 125.739 87.2999 125.739 66.0689C125.739 44.8379 107.929 27.6268 85.96 27.6268C63.9908 27.6268 46.1812 44.8379 46.1812 66.0689C46.1812 87.2999 63.9908 104.511 85.96 104.511Z" fill="white"/>
<path d="M85.4193 20.1642C73.6339 20.1648 62.2506 24.3058 53.3902 31.8157C44.5299 39.3257 38.7967 49.6923 37.2588 60.9843C35.721 72.2763 38.4834 83.7235 45.0314 93.1932C51.5793 102.663 61.4663 109.509 72.8502 112.457C84.2341 115.404 96.3386 114.252 106.908 109.214C117.478 104.176 125.793 95.5971 130.303 85.0746C134.812 74.5521 135.21 62.8041 131.422 52.0192C127.633 41.2343 119.917 32.1481 109.71 26.4536C102.325 22.3332 93.9472 20.1641 85.4193 20.1642ZM85.4193 102.331C76.9883 102.331 68.818 99.5063 62.3006 94.3376C55.7832 89.1689 51.322 81.9762 49.677 73.985C48.0321 65.9939 49.3052 57.6988 53.2795 50.5131C57.2537 43.3274 63.6832 37.6957 71.4725 34.5777C79.2617 31.4597 87.9287 31.0482 95.9967 33.4133C104.065 35.7785 111.035 40.774 115.718 47.5486C120.402 54.3232 122.511 62.4578 121.684 70.5662C120.858 78.6747 117.148 86.2554 111.186 92.0166C104.352 98.6206 95.0837 102.331 85.4193 102.331Z" fill="#FF8303"/>
<path d="M97.5659 54.3975L80.8641 70.538L74.7919 64.6698C73.6893 63.5467 72.1855 62.8716 70.5863 62.7817C68.9871 62.6917 67.4125 63.1937 66.1825 64.1855C65.5693 64.7178 65.0745 65.3651 64.7284 66.088C64.3823 66.811 64.192 67.5944 64.1693 68.3907C64.1465 69.187 64.2916 69.9794 64.5958 70.7197C64.9001 71.46 65.3571 72.1328 65.939 72.697L73.972 80.46C74.8758 81.3344 75.9491 82.0281 77.1305 82.5013C78.312 82.9746 79.5783 83.2182 80.8572 83.2182C82.1361 83.2182 83.4025 82.9746 84.5839 82.5013C85.7653 82.0281 86.8387 81.3344 87.7425 80.46L106.419 62.4687C107.001 61.9047 107.458 61.2321 107.762 60.4919C108.066 59.7518 108.211 58.9595 108.188 58.1634C108.166 57.3673 107.975 56.584 107.629 55.8612C107.283 55.1384 106.788 54.4913 106.175 53.9592C104.951 52.9619 103.38 52.4524 101.781 52.5342C100.183 52.6159 98.6762 53.2829 97.5679 54.3994L97.5659 54.3975Z" fill="#FE8303"/>
<path d="M31.6148 66.1753C28.0209 60.2271 24.7169 56.3153 23.2974 51.8979C23.2401 51.7271 23.1847 51.5489 23.1258 51.3499C23.6343 50.9089 23.965 50.3264 24.0654 49.6952C24.1658 49.064 24.03 48.4204 23.6796 47.8673C23.3872 47.4494 22.9666 47.118 22.4694 46.9133C22.6269 44.6944 23.8679 42.9204 27.8112 43.5304C30.8804 44.0093 36.9846 52.6569 37.7651 56.2873C70.0773 26.9217 90.914 23.8255 90.914 23.8255C86.3609 22.8219 82.8227 18.2555 78.7812 15.3294C81.1486 12.5269 83.7168 9.86853 86.4693 7.37141C89.757 4.92133 93.1856 2.63071 96.7409 0.509378C53.0199 -0.799948 29.7169 28.2948 0.828005 48.8583C1.19073 49.5597 1.53898 50.2658 1.9081 50.9765C7.77081 49.3109 13.6101 47.6492 19.4256 45.991C19.7375 46.221 20.0341 46.4498 20.336 46.6757C19.9078 46.7613 19.4984 46.9115 19.1249 47.1197C18.3684 47.5041 17.8106 48.1442 17.5733 48.9002C17.336 49.6561 17.4385 50.4663 17.8585 51.1539C17.9906 51.3403 18.1502 51.5095 18.3331 51.6564C17.5699 52.6372 17.162 53.8086 17.1659 55.0086C18.6232 67.6848 20.7228 80.3007 22.9302 92.8974C23.0514 93.6733 23.3067 94.427 23.6872 95.1308C23.811 95.3662 23.9642 95.5882 24.1435 95.7922L26.2133 96.8048L28.0332 95.3127L29.2758 97.465C30.2208 97.2697 31.1286 96.9481 31.9692 96.5109L34.6625 94.1113C37.7557 84.7999 36.6602 74.7579 31.6148 66.1753Z" fill="#FF8303"/>
</svg>
        <div class="w-72 h-40 space-y-4">
        <p class=" font-poppins text-xl text-orange text-center font-bold">
            You Are Accepted!
        </p>
        <p class=" font-poppins text-sm text-center font-normal">
            Congratulations! You can now begin your Tutoring journey. Go to your Tutor Dashboard to learn more.
        </p>
        </div>
      </div>
      </div>
    </page-layout>
  </template>
  
  <script>
  import pageLayout from "../../components/base/pageLayout.vue";
  export default {
    components: { pageLayout },
  };
  </script>
  